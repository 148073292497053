.chat-modal
	max-height: calc(100vh - 250px)!important

.chat-texts
	overflow: hidden
	overflow-y: auto

.text
	display: flex
	align-items: start
	margin: 0 0 15px 0
	.profile-pic
		min-width: 50px
		height: 50px
		flex: 0 0 50px
		border: 2px solid darken($white-ter, 4%)
		background: $white-ter
		border-radius: 100%
		margin: 24px 15px 0 0
		overflow: hidden
		display: flex
		justify-content: center
		align-items: center
		user-select: none
		font:
			size: 18px
			family: $font-medium
		img
			max-width: 100%
			height: auto
			border-radius: 100%
			// object-fit: cover
	&-wrapper
		max-width: 65%
		position: relative
	&-actions
		display: none
		position: absolute
		top: 50%
		right: -30px
		flex-direction: column
		justify-content: center
		align-items: center
		margin: -20px 0 0 0
		&.single
			margin-top: -10px
		button
			margin: 0
			padding: 0
			border: 0
			background: transparent
		.icon
			opacity: 0.5
			cursor: pointer
			&:hover
				opacity: 1
			&.trash
				svg
					width: 20px

	&-content 
		background: linear-gradient(to right, #f7f7f8, #f1f1f1)
		padding: 10px 20px
		border-radius: 0 20px 20px 20px
		font-size: 17px
		letter-spacing: -0.01em
		// width: 130%
		color: $grey-darker
		.icon-download
			display: block
			width: 40px
			height: auto
			margin: 0 auto
			svg
				width: 100%
				fill: $grey-light
				transition: $transition-base
			&:hover
				svg
					fill: $grey-dark
	.timestamp,
	.username
		display: block
		font-size: 14px
		margin: 0 0 5px 0
		letter-spacing: -0.01em
	.timestamp
		font-size: 12px
		margin: 3px 0 0 0
		text-align: right
		&-action
			float: left
			font-size: 11px
			padding: 2px 5px 0 0
			a
				display: block
				color: inherit
				text-decoration: none
				background: transparent
				&:hover
					color: $primary

	&.deleted
		opacity: 0.4
		pointer-events: none
		user-select: none
	&.sent,
	&.manager,
	&.master
		flex-direction: row-reverse
		.profile-pic
			margin-right: 0
			margin-left: 15px
		.text-content
			//background: linear-gradient(to right, #313131, #000)
			background: linear-gradient(to right, $secondary, darken($secondary, 5%))
			color: white
			border-radius: 20px 0 20px 20px
			.username
				text-align: right
			.icon-download
				svg
					fill: white
		.text-actions
			right: auto
			left: -30px
	&.manager
		.text-content
			background: linear-gradient(to right, lighten($success, 10%), darken($success, 10%))
	&.master
		.text-content
			background: linear-gradient(to right, lighten($danger, 10%), darken($danger, 10%))
	&:hover
		.text-actions
			display: flex

.send-message
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%

.message-text
	width: 80%
	flex-basis: 80%
	background: white
	padding: 6px 15px
	margin-right: 15px
	border-radius: 30px
	display: flex
	align-items: center
	justify-content: space-between
	background: white
	input
		padding: 0.5rem
		border: none
		background: transparent
		color: $grey-light
		width: 100%
		font-size: 15px
		&:focus
			color: $secondary

.attachment
	position: relative
	top: 4px
	width: 22px !important
	place-items: center
	cursor: pointer
	opacity: 0.5
	svg
		width: 100%
	&:hover
		opacity: 1

.history
	padding: 0
	margin: 0
	li
		background: $white-ter
		border-radius: 10px
		margin-bottom: 10px
		padding: 10px 20px
		color: $primary
	&-title 
		margin-top: 5px
		opacity: 0.5
		letter-spacing: -0.015em
		&.author
			text-align: right
			font-size: 14px
			