// Import Bulma's core
@import '~bulma/sass/utilities/_all';

// Fonts
$font-light:      'Volvo Novum-Light';
$font-semilight:  'Volvo Novum-SemiLight';
$font-regular:    'Volvo Novum-Regular';
$font-medium:     'Volvo Novum-Medium';
$font-bold:       'Volvo Novum-Bold';

$family-primary: $font-light, system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Colors
$primary: $black-bis;
$secondary: #007bcd;
$secondary-invert: findColorInvert($secondary);
$danger: #ff4444;
$success: #27ae60;
$warning: #f7ca50;
$titles: hsl(224deg, 19%, 56%, 100%);
$label-color: $grey-light;
$body-color: $grey-light;
$title-color: $primary;

// Links
$link: $primary;
$link-hover: darken($primary, 25%);
$link-focus: $link;
$link-focus-border: $link;

// Forms
$label-weight: $weight-medium;
$label-color: $grey-light;

// Elements
$sidebar-width: 90px;
$tooltip-arrow-margin: 10px;
$shadow: 0 0 15px rgba($black, 0.02);

// Transition
$transition-base: all 0.2s ease-out;

// Map
$colors: (
	'white': (
		$white,
		$black
	),
	'black': (
		$black,
		$white
	),
	'gray': (
		$grey-light,
		$white
	),
	'light': (
		$light,
		$light-invert
	),
	'dark': (
		$dark,
		$dark-invert
	),
	'primary': (
		$primary,
		$primary-invert
	),
	'secondary': (
		$secondary,
		$secondary-invert
	),
	'info': (
		$info,
		$info-invert
	),
	'success': (
		$success,
		$success-invert
	),
	'warning': (
		$warning,
		$warning-invert
	),
	'danger': (
		$danger,
		$danger-invert
	)
);

// Import Bulma and Buefy
@import '~bulma';
@import '~buefy/src/scss/buefy';

// Import MD Icons
@import '~@mdi/font/css/materialdesignicons.css';

// Import Swatches
@import '~vue-swatches/dist/vue-swatches.css';
