// |------------------------------------------------------
// |------------------------------------------------------
// | Forms
// |------------------------------------------------------
// |------------------------------------------------------

.label
	position: relative
	color: $primary
	font-family: $font-medium
	z-index: 2!important

.field
	position: relative
	.select-all
		cursor: pointer
		position: absolute
		top: -27px
		right: 0
		z-index: 10
		font-size: 12px
		color: $secondary
		font-family: $font-medium
		text-transform: uppercase
		&.remover
			color: $danger
		&:hover
			color: $dark
	.fake
		display: flex
		justify-content: space-between
		border: 1px solid #dbdbdb
		border-radius: 4px
		.button
			margin-right: -20px
	.input
		box-shadow: none
		font-family: $font-light
		&:not(.is-small, .is-medium, .is-large)
			padding-top: 1.5em
			padding-bottom: 1.5em
			padding-right: 1.5em

	.select
		display: block
		&:not(.is-multiple, .is-small, .is-medium, .is-large)
			height: 3em
		&:after
			margin-top: -2px
		select
			width: 100%
			height: 100%

	.help
		display: none
		position: absolute
		color: $white
		background: $danger
		border-radius: 4px
		right: 15px
		bottom: -15px
		white-space: nowrap
		z-index: 10
		&.is-danger,
		&.is-success
			padding: 5px 10px
		&.is-danger
			display: block
			background: $danger
		&.is-success
			background: $success

.bonus
	.upload
		width: 100%
		display: flex
		flex-direction: row-reverse
		.file-cta
			border-radius: 0 4px 4px 0
			background: $secondary
			height: 3em
			&:hover
				background: darken($secondary, 10%)
		.file-name
			width: 100%
			padding-top: 12px
			max-width: none
			border-radius: 4px 0  0 4px
			border-left-width: 1px
			height: 3em

.field-helper
	display: block
	margin-bottom: 0
	font-size: 75%
	@include tablet
		margin-top: 3px
		margin-bottom: -21px

.select-eligibility
	margin: 0
	min-width: 170px
	justify-content: center
	select
		min-width: 100px

.input-text
	border: 0
	appearance: none
	padding: 0
	outline: none
	user-select: none
	opacity: 0.3
