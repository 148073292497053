// |------------------------------------------------------
// |------------------------------------------------------
// | Auth
// |------------------------------------------------------
// |------------------------------------------------------

.auth
	height: 100vh
	height: calc(var(--vh, 1vh) * 100)
	overflow: hidden

	.logo
		margin-bottom: spacing()
		@include desktop
			margin-bottom: $block-spacing * 2

	.columns
		margin: 0
		height: 100%

	&__image
		display: block
		height: 100%
		padding: 0
		position: relative
		background: $black
		.image
			// opacity: 0.45
			height: 100%
		video
			position: absolute
			right: 0
			bottom: 0
			min-width: 100%
			min-height: 100%
			object-fit: cover
			z-index: 1
			background: $black

		&::after
			display: none
			content: ''
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			z-index: 5
			background: rgba($primary, 0.45)

	&__forgot
		position: absolute
		top: 2px
		right: 0
		font-size: 14px
		z-index: 10

	&__content
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		height: 100%
		@include mobile
			max-width: 85%
			margin: 0 auto

		> span
			display: block
			width: 100%

	&__form
		display: block
		max-width: 400px
		margin: 0 auto

		.icon
			opacity: 0.2
			&.has-text-success,
			&.has-text-danger
				opacity: 1

		.input
			background: $white
			border: 2px solid $white
			height: 3em
			transition: $transition-base
			&:focus
				border-color: $grey-light

		.button
			height: auto
			padding: 0.8em 2.5em
