// |------------------------------------------------------
// |------------------------------------------------------
// | Profile
// |------------------------------------------------------
// |------------------------------------------------------

.profile
	&__column
		background: $white
		border-radius: $radius * 2
		padding: spacing()
		box-shadow: $shadow
		@include desktop
			min-height: calc(100vh - 130px)

	&__sidebar
		@include desktop
			max-width: 400px

	&__name
		color: $primary
		padding: spacing(2) 0 0 0
		line-height: 1.1
		letter-spacing: -0.05em
		max-width: 220px
		@include mobile
			max-width: 170px

	&__image
		position: relative
		background: $grey-lightest
		width: 90px
		height: 90px
		border-radius: $radius
		img
			display: block
		&__edit
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			border: none
			background: rgba(0, 0, 0, 0.3)
			opacity: 0
			transition: $transition-base
			.icon
				opacity: 1
				fill: white
		&:hover
			.button
				opacity: 1
		img
			width: 100%
			border-radius: $radius

	&__list
		list-style: none
		li
			display: flex
			justify-content: space-between
			align-items: center
			padding: 5px 0
		&__key,
		&__value
			font-size: 90%
		&__key
			font-family: $font-medium
			color: $primary
		&__value
			color: $body-color
			font-weight: normal

	&__navigation
		padding-top: spacing(2)
		li
			margin: 5px 0
			a
				display: flex
				justify-content: flex-start
				align-items: center
				// font-size: 85%
				font-family: $font-regular
				color: $body-color
				border-radius: $radius
				padding: spacing(2) spacing(1.5)
				transition: $transition-base
				.svg
					position: relative
					top: -1px
					display: flex
					width: 20px
					height: 20px
					margin-right: spacing(2.5)
					opacity: 0.3
					&.bell
						top: 0
				&:hover,
				&.is-active
					color: $primary
					background: $white-ter
					.svg
						opacity: 1

	&__section
		@include desktop
			padding-left: spacing() 0 0 26%

	&__field
		display: flex
		.po-password-strength-bar
			position: absolute
			right: 15%
			left: 55%
			bottom: 0
			z-index: 10

		.field
			display: flex
			position: relative
			margin-bottom: spacing()
			@include desktop
				max-width: 85%
			@include touch
				flex-direction: column
		.label
			margin: 0
			display: flex
			align-items: center
			font-family: $font-medium
			@include desktop
				width: 30%
			@include touch
				margin-bottom: spacing(2)
		.control
			@include desktop
				width: 70%
			input,
			textarea
				background: $white-ter
				border-color: transparent
				// &:focus
				// 	background: $grey-lightest

.avatar
	position: relative

	&__edit
		position: absolute
		z-index: 10
		top: 10px
		right: 10px
		background: #fff
		border: none
		padding: 0 7px
		height: auto
		.edit
			fill: $green
			.icon
				opacity: 1

	&__add
		width: 100%
		min-height: 250px
		border: 2px dashed $grey-light
		transition: $transition-base
		svg
			width: 100px
			height: auto
			transition: $transition-base
			opacity: 0.6
			path
				fill: $primary
		&:hover
			// border-color: $primary
			svg
				opacity: 1

.vue-advanced-cropper
	&__background
		border-radius: $radius
