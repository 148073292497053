// |------------------------------------------------------
// |------------------------------------------------------
// | Notifications
// |------------------------------------------------------
// |------------------------------------------------------

.notifications
	// min-height: 65px
	// border-left: 1px solid rgba($black, 0.1)
	@include touch
		position: fixed
		top: 0
		right: 50px
		z-index: 5

	&__button
		background: transparent
		border: none
		cursor: pointer
		position: relative
		padding: 5px 15px 10px
		margin-top: 17px
		.svg
			width: 30px
			opacity: 0.7
			&:hover
				opacity: 1
		&__icon
			width: 20px
			height: 20px
			font-size: 8px
			position: absolute
			top: 0
			right: 5px
			background: $danger
			color: $white
			display: flex
			justify-content: center
			align-items: center
			border-radius: 100%

	&__head
		display: flex
		align-items: center
		justify-content: space-between
		width: 100%
		height: 65px
		text-transform: uppercase
		letter-spacing: 0.1em
		color: $black
		font:
			family: $font-medium
			weight: normal
			size: 14px
		padding: spacing()
		border-bottom: 1px solid rgba($grey-lighter, 0.5)

	&__list
		position: fixed
		width: 450px
		max-width: 90%
		height: 100%
		background: white
		top: 0
		right: -120vw
		z-index: 100
		transition: all 0.75s ease-out
		//padding: spacing()
		//padding-top: spacing() * 3
		//box-shadow: 0 0 10px $grey-lightest
		.icon-button
			display: block
			position: absolute
			top: 20px
			right: 20px
		.error__wrapper
			height: 100%
			box-shadow: none
		&.active
			right: 0
		&__title
			font:
				family: $font-regular
				weight: normal
			color: inherit
			line-height: 1
			margin: 0
			padding-bottom: 5px
		&__description
			margin-top: 3px
			font:
				size: 0.85em
				family: $font-light
			color: $grey-lighter
		&__title,
		&__description
			// white-space: nowrap
			// overflow: hidden
			// text-overflow: ellipsis
			// max-width: 245px
		ul
			height: 100%
			overflow: auto
			padding: spacing(1.5)
			&::-webkit-scrollbar
				width: 4px
			&::-webkit-scrollbar-thumb
				border-radius: 5px
			li
				border-bottom: 1px solid $grey-lightest
				padding: spacing(2.5) 0
				// border-bottom: 1px solid lighten($grey-lighter, 5%)
				&:last-child
					border-bottom-color: $white
			.item
				display: flex
				padding: 5px
				// border-radius: 2px
				// transition: background 0.1s linear
				width: 100%
				color: $grey
				//background: darken($white-bis, 5%)
				border-radius: 5px
				transition: all 0.2s ease-out
				letter-spacing: -0.02em
				.svg,
				.mdi
					display: flex
					justify-content: center
					align-items: center
					border-radius: 5px
					font-size: 1.35em
					width: 50px
					height: 45px
					// margin: 0 spacing(4) 0 0
				&.read
					color: $grey
					background: transparent
			a.item
				&:hover
					background: $secondary
					color: $white
					border-bottom-color: $secondary
					.svg,
					.mdi
						color: inherit!important
